import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import useMedia from "use-media"
import { colors } from "./theme"

const ImageMenu = styled.div`
  width: 100%;
  height: 25vw;

  & > div {
    display: inline-block;
    width: 25%;
    height: 100%;
    transition: 0.4s;
    cursor: pointer;
  }

  a {
    opacity: 0;
    transition: opacity 0.3s;
    :hover {
      opacity: 1;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    text-decoration: none;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    color: #fff;
  }
`

const Content = styled.div`
  width: 100%;
  padding: 60px 60px 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .footer-menu {
    display: flex;

    div {
      padding: 0;
      margin-right: 60px;
    }
    .usluge {
      margin-right: 40px;
    }
    .cesta-pitanja {
      margin-right: 60px;
    }
    .blog {
      margin-right: 70px;
    }

    a h4 {
      font-size: 16px;
      color: rgba(24, 24, 28, 0.6);
      font-weight: 400;
    }
    a {
      text-decoration: none;
      display: block;
      margin-bottom: 16px;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: rgba(24, 24, 28, 0.6);
    }
  }

  @media (max-width: 1250px) {
    padding: 40px 0 20px;
    border-top: 0.1px solid ${colors.pinkpastel};
    width: calc(100% - 120px);
    height: 100vh;
    max-height: 750px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .footer-menu {
      flex-direction: column;
      margin: 0 auto 20px;
      text-align: center;
      div,
      .usluge,
      .cesta-pitanja,
      .blog {
        margin: 0;
      }
      h4 {
        margin: 0;
      }
      div a {
        padding: 20px;
        margin: 0;
      }
    }
  }
`

const Subtext = styled.div`
  display: inline-block;
  text-align: center;
  width: calc(100% - 120px);
  margin: 0 60px;
  padding: 12px 0 20px;
  border-top: 0.1px solid ${colors.pinkpastel};
  font-weight: 200;
  font-size: 10px;
  color: #e8397a;
`

const StyledLogo = styled(Img)`
  width: 25%;
  max-width: 233px;
  min-width: 96px;
  margin-bottom: 60px;
  @media (max-width: 1250px) {
    width: 96px;
    height: 96px;
    margin: 0 auto 40px;
  }
`

const Footer = ({ exception }) => {
  const data = useStaticQuery(graphql`
    query {
      oNama: file(relativePath: { eq: "footer/o-nama.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blog: file(relativePath: { eq: "footer/blog.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kontakt: file(relativePath: { eq: "footer/kontakt.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      radionice: file(relativePath: { eq: "footer/radionice.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paketi: file(relativePath: { eq: "footer/paketi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cestaPitanja: file(relativePath: { eq: "footer/cesta-pitanja.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darujem: file(relativePath: { eq: "footer/darujem.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      footerlogo: file(relativePath: { eq: "tiha_pratnja_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 233) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const isMobile = useMedia({ maxWidth: 1250 })
  return (
    <>
      {isMobile ? (
        ""
      ) : (
        <ImageMenu>
          {exception === "usluge" ? (
            <>
              <BackgroundImage
                Tag="div"
                fluid={data.oNama.childImageSharp.fluid}
                backgroundColor="#e5e5e5"
              >
                <Link to="/o-nama">O Nama</Link>
              </BackgroundImage>
              <BackgroundImage
                Tag="div"
                fluid={data.blog.childImageSharp.fluid}
                backgroundColor="#e5e5e5"
              >
                <Link to="/blog">Blog</Link>
              </BackgroundImage>
            </>
          ) : (
            <>
              <BackgroundImage
                Tag="div"
                fluid={data.radionice.childImageSharp.fluid}
                backgroundColor="#e5e5e5"
              >
                <Link to="/usluge#radionice">Radionice</Link>
              </BackgroundImage>
              <BackgroundImage
                Tag="div"
                fluid={data.paketi.childImageSharp.fluid}
                backgroundColor="#e5e5e5"
              >
                <Link to="/usluge#paketi">Paketi</Link>
              </BackgroundImage>
            </>
          )}
          {exception === "cesta-pitanja" ? (
            <BackgroundImage
              Tag="div"
              fluid={data.blog.childImageSharp.fluid}
              backgroundColor="#e5e5e5"
            >
              <Link to="/blog">Blog</Link>
            </BackgroundImage>
          ) : (
            <BackgroundImage
              Tag="div"
              fluid={data.cestaPitanja.childImageSharp.fluid}
              backgroundColor="#e5e5e5"
            >
              <Link to="/cesta-pitanja">Česta pitanja</Link>
            </BackgroundImage>
          )}
          {exception === "usluge" ? (
            <BackgroundImage
              Tag="div"
              fluid={data.kontakt.childImageSharp.fluid}
              backgroundColor="#e5e5e5"
            >
              <Link to="/kontakt">Kontakt</Link>
            </BackgroundImage>
          ) : (
            <BackgroundImage
              Tag="div"
              fluid={data.darujem.childImageSharp.fluid}
              backgroundColor="#e5e5e5"
            >
              <Link to="/usluge#darujem">Darujem</Link>
            </BackgroundImage>
          )}
        </ImageMenu>
      )}
      <Content isMobile={isMobile}>
        <StyledLogo
          fluid={data.footerlogo.childImageSharp.fluid}
          isMobile={isMobile}
        />
        <div className="footer-menu">
          <div>
            <Link to="/">
              <h4>Naslovnica</h4>
            </Link>
            {isMobile ? (
              ""
            ) : (
              <>
                <Link to="/">Doula</Link>
                {/* // todo: this should actually scroll to that part still */}
                <Link to="/">Vaše povjerenje</Link>
              </>
            )}
          </div>
          <div>
            <Link to="/o-nama">
              <h4>O nama</h4>
            </Link>
            {isMobile ? (
              ""
            ) : (
              <>
                <Link to="/o-nama#o-meni">O meni</Link>
                <Link to="/o-nama#druzimo-se">Družimo se</Link>
                <Link to="/o-nama#moji-suradnici">Moji suradnici</Link>
              </>
            )}
          </div>
          <div>
            <Link to="/usluge">
              <h4>Usluge</h4>
            </Link>
            {isMobile ? (
              ""
            ) : (
              <>
                <Link to="/usluge#paketi-usluga">Paketi usluga</Link>
                <Link to="/usluge#doula-mentor">Doula mentor</Link>
                <Link to="/usluge#radionice">Radionice</Link>
                <Link to="/usluge#darujem">Darujem</Link>
                <Link to="/usluge#korisne-poveznice">Korisne poveznice</Link>
              </>
            )}
          </div>
          <div className="cesta-pitanja">
            <Link to="/cesta-pitanja">
              <h4>Česta pitanja</h4>
            </Link>
          </div>
          <div>
            <Link to="/blog">
              <h4>Blog</h4>
            </Link>
          </div>
          <div>
            {/* // todo: link these to appropriate sections */}
            <Link to="/kontakt">
              <h4>Kontakt</h4>
            </Link>
            {isMobile ? (
              ""
            ) : (
              <>
                <Link to="/kontakt">Doula Sanja</Link>
                <Link to="/kontakt">Korisni kontakti</Link>
              </>
            )}
          </div>
        </div>
      </Content>
      <Subtext>
        DOULA SANJA {new Date().getFullYear()} | Sva prava pridržana.
      </Subtext>
    </>
  )
}

export default Footer
