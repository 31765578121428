import React from 'react'
import PropTypes from 'prop-types'
import { Location } from '@reach/router'
import useMedia from 'use-media'
import { MainContent } from './sections'
import Cookies from './Cookies'

import Header from './Header'
import Footer from './Footer'
import './layout.css'

const Layout = ({ children, subsection = 'Doula', olive, footerException }) => {
  const isMobile = useMedia({ maxWidth: 800 })
  return (
    <>
      <Location>
        {({ location }) => (
          <Header
            subsection={subsection}
            location={location}
            olive={olive}
            isMobile={isMobile}
          />
        )}
      </Location>
      <MainContent>{children}</MainContent>
      <Cookies />
      <Footer exception={footerException} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  subsection: PropTypes.string,
  olive: PropTypes.bool,
}

export default Layout
