import styled from 'styled-components'
import { animated } from 'react-spring'
import { lighten } from 'polished'
import { colors } from '../theme'

// todo: more dynamic scroll functionality and transitions
export const StyledHeader = styled(animated.header)`
  /* this gives space for the expanded menu below */
  padding: 0 0 40px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  transition: transform 0.4s;
  z-index: 9999;

  .header-container {
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    ${props => (props.isMobile ? `padding: 0 15px` : 'padding: 0 40px')};
  }
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div#logo-image {
    width: 70px;
    height: 70px;
    /* margin-right: 30px; */

    ${props =>
      props.isMobile
        ? `
      width: 50px;
      height: 50px;
    `
        : ''}
  }
`

export const TopLogoText = styled(animated.div)`
  position: absolute;
  top: 38px;
  left: 130px;
  ${props =>
    props.isMobile
      ? `
    top: 15px;
    left: 74px
  `
      : ''}
  width: 160px;

  h1 {
    color: ${props => (props.olive ? '#9C997B' : colors.pinkdirty)};
    font-size: ${props => (props.isMobile ? `20px` : `28px`)};
    text-transform: uppercase;
    font-weight: 300;
    text-decoration: none;
    margin: 0;
  }
  h3 {
    color: ${colors.greymid};
    font-size: ${props => (props.isMobile ? `10px` : `11px`)};
    font-weight: 300;
    text-decoration: none;
    margin: 0;
  }
`
export const ScrollLogoText = styled(animated.div)`
  position: absolute;
  ${props =>
    props.isMobile ? `top: 26px; left: 69px;` : `top: 30px; left: 108px;`}
  color: #7f7f7f;

  h2 {
    margin: 0 10px;
    display: inline-block;
    font-weight: 400;
    text-decoration: none;
    font-size: ${props => (props.isMobile ? 16 : 20)}px;
    text-transform: uppercase;
    font-variant: small-caps;
  }
  h3 {
    margin: 0 10px;
    display: inline-block;
    font-size: ${props => (props.isMobile ? 16 : 20)}px;
    font-weight: 300;
    text-decoration: none;
  }
`

export const StyledMenu = styled(animated.nav)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 740px; */
  position: relative;

  #${props => props.currentPage} {
    color: #c8c5d2;
    svg {
      path {
        fill: #c8c5d2;
      }
    }
  }

  a#o-nama,
  a#usluge {
    padding: 0 3rem 0 2.5rem;
  }
  ${props =>
    props.expanded === 'o-nama' &&
    `
        #o-nama {
          border-bottom: 2px solid ${props.olive ? '#9C997B' : '#f49dba'};
        }
    `}
  ${props =>
    props.expanded === 'usluge' &&
    `
        #usluge {
          border-bottom: 2px solid ${props.olive ? '#9C997B' : '#f49dba'};
        }
    `}

  a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2.5rem;
    font-family: 'Fira Sans Condensed';
    height: 40px;
    font-weight: 300;
    font-size: 16px;
    text-transform: uppercase;
    color: ${colors.greymid};
    text-decoration: none;
    text-align: center;
    border-bottom: 2px solid transparent;

    svg {
      position: absolute;
      right: 9px;
      margin: 0 0.6rem;
      path {
        fill: ${lighten(0.2, colors.greymid)};
      }
    }

    :hover {
      border-bottom: 2px solid ${props =>
        props.olive ? '#9C997B' : '#f49dba'};
      /* svg {
        path {
          fill: ${lighten(0.3, colors.greydark)};
        }
      } */
    }
  }
`

export const ExpandedMenu = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 40px;
  background: ${props => (props.olive ? '#9C997B' : '#f49dba')};
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 1.2rem;
    font-size: 12px;
    text-transform: uppercase;
    color: #ffffff;
    text-decoration: none;

    :hover {
      background: ${props => (props.olive ? '#eeeee9' : '#fef5f8')};
      color: ${props => (props.olive ? '#9c997b' : '#f49dba')};
    }
  }
`

export const Hamburger = styled.div`
  position: absolute;
  right: 50px;
  top: ${props => (props.scrollMode ? '27px' : '58px')};
  ${props =>
    props.isMobile
      ? `
    top: 27px;
    right: 16px;
  `
      : ''}

  cursor: pointer;
  width: 36px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  div {
    border-radius: 7px;
    width: 100%;
    height: 4px;
    background: ${props => (props.olive ? '#9C997B' : '#f49dba')};
  }
`
