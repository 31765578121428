import React, { useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useSpring, useTransition, animated } from 'react-spring'
import useMedia from 'use-media'

import FullScreenNav from './FullScreenNav'
import SvgDownTriangle from '../../icons/DownTriangle'
import useScroll from '../../hooks/useScroll'
import {
  StyledHeader,
  Title,
  TopLogoText,
  ScrollLogoText,
  StyledMenu,
  ExpandedMenu,
  Hamburger,
} from './Header.styled'

const Header = ({ location, subsection, olive = false, isMobile }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoOlive: file(relativePath: { eq: "logo_olive.png" }) {
        childImageSharp {
          fluid(maxWidth: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // STATE

  const [currentPage, setCurrentPage] = useState()
  const [scrollMode, setScrollMode] = useState(false)
  const [hideScroll, setHideScroll] = useState(false)

  const showHamburger = useMedia({ maxWidth: 1200 })
  const isSmallMobile = useMedia({ maxWidth: 370 })
  const [showFullScreenNav, setShowFullScreenNav] = useState(false)

  // EFFECTS

  useEffect(() => {
    const path = location.pathname
    switch (path) {
      case '':
        return setCurrentPage('Home')
      case '/':
        return setCurrentPage('Home')
      case '/o-nama':
        return setCurrentPage('O nama')
      case '/o-nama/':
        return setCurrentPage('O nama')
      case '/usluge':
        return setCurrentPage('Usluge')
      case '/usluge/':
        return setCurrentPage('Usluge')
      case '/cesta-pitanja':
        return setCurrentPage('Česta pitanja')
      case '/cesta-pitanja/':
        return setCurrentPage('Česta pitanja')
      case '/blog':
        return setCurrentPage('Blog')
      case '/blog/':
        return setCurrentPage('Blog')
      case '/kontakt':
        return setCurrentPage('Kontakt')
      case '/kontakt/':
        return setCurrentPage('Kontakt')
      default:
        return setCurrentPage('Blog')
    }
  }, [location.pathname])

  // SCROLLED MENU TRANSITIONS
  const scrollEffect = useCallback(({ previous, current }) => {
    setHideScroll(current < -40 && previous > current)
    setScrollMode(current < -40)
  }, [])
  useScroll(scrollEffect, [], null)

  // actual header
  const [headerProps, setHeaderProps] = useSpring(() => ({
    height: 160,
    transform: 'translate3d(0, 0px, 0)',
    config: { duration: 100 },
  }))
  // logo
  const [logoProps, setLogoProps] = useSpring(() => ({
    width: '70px',
    height: '70px',
    position: 'relative',
    top: '9px',
  }))
  // nav section
  const [navProps, setNavProps] = useSpring(() => ({ top: '18px' }))
  // title section
  const titleTransition = useTransition(scrollMode, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  // expander
  const [expanded, setExpanded] = useState(null)
  const [expandedContent, setExpandedContent] = useState()
  const expanderTimeout = useRef()
  const expanderTransition = useTransition(expanded, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  useEffect(() => {
    setHeaderProps({
      height: isMobile ? 110 : scrollMode ? 120 : 160,
      transform: `translate3d(0, ${hideScroll ? -160 : 0}px, 0)`,
    })
    setLogoProps({
      width: isMobile || scrollMode ? '50px' : '70px',
      height: isMobile || scrollMode ? '50px' : '70px',
      position: 'relative',
      top: isMobile ? '4px' : scrollMode ? '0px' : '9px',
    })
    setNavProps({ top: scrollMode ? '0px' : '18px' })
  }, [
    scrollMode,
    hideScroll,
    setHeaderProps,
    setLogoProps,
    setNavProps,
    isMobile,
  ])

  useEffect(() => {
    if (expanded === 'o-nama')
      setExpandedContent(
        <>
          <Link to="/o-nama#o-meni">O Meni</Link>
          <Link to="/o-nama#druzimo-se">Družimo se</Link>
          <Link to="/o-nama#moji-suradnici">Moji Suradnici</Link>
        </>
      )
    if (expanded === 'usluge')
      setExpandedContent(
        <>
          <Link to="/usluge#paketi-usluga">Paketi usluga</Link>
          <Link to="/usluge/#radionice">Radionice</Link>
          <Link to="/usluge/#darujem">Darujem</Link>
          <Link to="/usluge/#korisne-poveznice">Korisne poveznice</Link>
          <Link to="/usluge/#doula-mentor">Doula mentor</Link>
        </>
      )
  }, [expanded])

  const handleExpand = useCallback(link => {
    clearTimeout(expanderTimeout.current)
    if (link === 'prev') return
    setExpanded(link)
  }, [])

  const expanderLeave = useCallback(() => {
    expanderTimeout.current = setTimeout(() => handleExpand(null), 2000)
  }, [handleExpand])

  return (
    <StyledHeader style={headerProps} isMobile={isMobile}>
      <div className="header-container">
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Title isMobile={isMobile}>
            <animated.div id="logo-image" style={logoProps}>
              {olive ? (
                <Img fluid={data.logoOlive.childImageSharp.fluid} />
              ) : (
                <Img fluid={data.logo.childImageSharp.fluid} />
              )}
            </animated.div>
            {titleTransition.map(({ item, key, props }) =>
              item ? (
                <ScrollLogoText style={props} isMobile={isMobile}>
                  <h2>{currentPage}</h2>
                  {location.pathname === '/blog' ||
                  location.pathname === '/blog/' ||
                  isSmallMobile ? (
                    ''
                  ) : (
                    <>
                      |<h3>{subsection}</h3>
                    </>
                  )}
                </ScrollLogoText>
              ) : (
                <TopLogoText style={props} olive={olive} isMobile={isMobile}>
                  <h1>Doula</h1>
                  <h3>
                    Tiha pratnja kroz trudnoću,
                    <br /> na porodu i u danima babinja
                  </h3>
                </TopLogoText>
              )
            )}
          </Title>
        </Link>
        {showHamburger ? (
          <Hamburger
            scrollMode={scrollMode}
            isMobile={isMobile}
            olive={olive}
            onClick={() => setShowFullScreenNav(true)}
          >
            <div id="one" />
            <div id="two" />
            <div id="three" />
          </Hamburger>
        ) : (
          <StyledMenu
            style={navProps}
            expanded={expanded}
            olive={olive}
            currentPage={location.pathname.split('/')[1]}
          >
            <Link
              to="/o-nama/"
              onMouseEnter={() => handleExpand('o-nama')}
              onMouseLeave={expanderLeave}
              id="o-nama"
            >
              O Nama <SvgDownTriangle />
            </Link>
            <Link
              to="/usluge/"
              onMouseEnter={() => handleExpand('usluge')}
              onMouseLeave={expanderLeave}
              id="usluge"
            >
              Usluge <SvgDownTriangle />
            </Link>
            <Link to="/cesta-pitanja" id="cesta-pitanja">
              Česta Pitanja
            </Link>
            <Link to="/blog" id="blog">
              Blog
            </Link>
            <Link to="/kontakt" id="kontakt">
              Kontakt
            </Link>
          </StyledMenu>
        )}
        {expanderTransition.map(
          ({ item, key, props }) =>
            item && (
              <ExpandedMenu
                key={key}
                style={props}
                onMouseEnter={() => handleExpand('prev')}
                onMouseLeave={expanderLeave}
                olive={olive}
              >
                {expandedContent}
              </ExpandedMenu>
            )
        )}
      </div>
      {showFullScreenNav ? (
        <FullScreenNav
          onClose={() => setShowFullScreenNav(false)}
          isMobile={isMobile}
        />
      ) : (
        ''
      )}
    </StyledHeader>
  )
}
Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  subsection: PropTypes.string.isRequired,
  olive: PropTypes.bool,
}

export default Header
