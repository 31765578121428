import styled from "styled-components"
import { colors } from "./theme"

export const MainContent = styled.main`
  margin: 0;
`

export const VHContainer = styled.section`
  min-height: 100vh;
  width: 100%;
  padding: 60px;
  background: ${props => `${props.bg}` || ""};
  overflow: hidden;
  @media (max-width: 850px) {
    padding: 40px 40px;
  }
  @media (max-width: 670px) {
    min-height: 93vh;
  }
  @media (max-width: 650px) {
    padding: 30px 20px;
  }
  @media (max-width: 390px) {
    padding: 20px;
  }
`

export const TitleSection = styled(VHContainer)`
  position: relative;
  background: ${props => props.bg || colors.greylight};
  ${props => (props.img ? `background: transparent;` : "")}
  overflow: hidden;

  div {
    position: absolute;
    left: 100px;
    bottom: 7vh;
    ${props =>
      props.img
        ? `
        h2 {
          text-shadow: 0 0 80px #18181c,
                      0 0 80px #18181c;
        }
        p {
          text-shadow: 0 0 80px #18181c,
                        0 0 80px #18181c,
                      0 0 80px #18181c;
        }
    `
        : ""}
  }

  h2 {
    margin: 0;
    margin-bottom: 0.7rem;
    color: ${props => props.color || "#fff"};
    font-weight: 500;
    font-size: 1.8rem;
    max-width: 600px;
  }

  p {
    margin: 0;
    margin-bottom: 1.4rem;
    width: 100%;
    max-width: 700px;
    font-weight: 300;
    font-size: 1.5rem;
    color: ${props => props.color || "#fff"};
  }

  @media (max-width: 1200px) {
    ${props =>
      props.img
        ? `
        h2, p {
          text-shadow: 0 0 30px #18181c;
        }
    `
        : ""}
    div {
      left: 60px;
      bottom: 60px;
      width: 620px;
    }
    h2 {
      font-size: 1.6rem;
    }
    p {
      font-size: 1.2rem;
      width: 100%;
    }
  }

  @media (max-width: 800px) {
    div {
      left: 30px;
      bottom: 30px;
      width: 490px;
    }
    h2 {
      font-size: 1.4rem;
    }
    p {
      font-size: 1.1rem;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    div {
      left: 30px;
      bottom: 50px;
      /* width: 320px; */
    }
    h2 {
      /* font-size: 1.1rem; */
    }
    p {
      /* font-size: 0.9rem; */
      max-width: 80%;
    }
  }

  @media (max-width: 375px) {
    h2.kontakt {
      width: 215px;
    }
  }

  h2.kontakt {
    @media (max-width: 1200px) {
      max-width: 546px;
    }
    @media (max-width: 1100px) {
      max-width: 478px;
    }
    @media (max-width: 800px) {
      max-width: 426px;
    }
    @media (max-width: 600px) {
      max-width: 373px;
    }
    @media (max-width: 500px) {
      max-width: 306px;
    }
  }
`

export const ParagraphSection = styled(VHContainer)`
  background: ${props =>
    props.light ? colors.pinklightest : colors.pinktouch};
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 80vw;
    max-width: 720px;
  }
  svg {
    margin: ${props => (props.light ? "60px 0 0 100px" : "60px 100px 0 0")};
    order: ${props => (props.light ? 1 : 0)};
  }
  h3 {
    font-weight: 500;
    font-size: 40px;
    color: ${colors.pinkdirty};
  }
  p {
    max-width: 100%;
    font-weight: 200;
    font-size: 1.1rem;
    line-height: 1.7rem;
    color: ${colors.greymid};
    b {
      font-weight: 400;
    }
  }

  @media (max-width: 1150px) {
    svg {
      margin: ${props => (props.light ? "60px 0 0 60px" : "60px 60px 0 0")};
    }
  }

  @media (max-width: 1050px) {
    padding: 60px 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    svg {
      margin: 0 0 50px;
      order: 0;
    }
    div {
      width: 70%;
      order: 1;
    }
    h3 {
      margin: 0 0 25px;
    }
    p {
      font-size: 18px;
      line-height: 32px;
    }
  }

  @media (max-width: 650px) {
    padding: 60px 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    .icon {
      width: 320px;
      margin: 0 auto 40px;
      svg {
        margin: 0;
      }
    }
    svg {
      margin: 0 16% 50px;
      left: 60px;
    }
    div {
      max-width: 320px;
      margin: 0 auto;
      width: 100%;
    }
    h3 {
      margin: 0 0 25px;
    }
  }

  @media (max-width: 375px) {
    padding: 60px 20px;
  }
`

export const OverviewTitle = styled(VHContainer)`
  background: ${colors.pinklightest};
  display: flex;
  justify-content: center;
  align-items: center;
  & > span {
    height: 100%;
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fefbfc;
    div {
      text-align: center;
      max-width: 250px;
      height: 350px;
      margin: 0 30px;
    }
    h3 {
      font-weight: 500;
      font-size: 1.5rem;
      color: #f29eba;
    }
    p {
      font-weight: 200;
      font-size: 1.1rem;
      line-height: 1.7rem;
      color: ${colors.greymid};
    }

    @media (max-width: 950px) {
      flex-direction: column;
      justify-content: space-around;
      div {
        max-width: none;
        margin: 30px 0;
        height: auto;
        max-width: 450px;
      }
      p {
        margin: 0 auto;
      }
    }

    @media (max-width: 650px) {
      flex-direction: column;
      div {
        height: 160px;
        max-width: none;
      }
      h3 {
        font-size: 1.25rem;
        margin: 10px 0 5px;
      }
      p {
        font-size: 1rem;
        line-height: 1.1rem;
        margin: 0 auto;
        max-width: 330px;
      }
    }
  }
`

export const CircleSection = styled(VHContainer)`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => (props.bg ? colors[props.bg] : colors.pinklightest)};
  p {
    z-index: 1;
    max-width: 800px;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 2.2rem;
    text-align: center;
    color: #747477;
    b {
      font-weight: 400;
      color: ${colors.pinkdirty};
    }
    strong {
      font-weight: 400;
      color: ${colors.pinkhot};
    }
  }
  p.druzimo-se {
    text-align: left;
    position: relative;
    font-size: 24px;
  }
  p.doula-mentor {
    font-size: 22px;
    line-height: 36px;
  }

  & > div {
    display: inline-block;
    width: 75%;
    margin: 3rem 6rem 6rem;
    font-weight: 300;
    font-size: 0.9rem;
    line-height: 1.6rem;
    color: ${colors.greymid};
  }

  @media (max-width: 650px) {
    padding: 0 30px;
    p {
      font-size: 24px;
      line-height: 36px;
    }
    p.druzimo-se {
      text-align: left;
      position: relative;
      font-size: 18px;
      line-height: 32px;
    }
    p.doula-mentor {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media (max-width: 390px) {
    padding: ${props => (props.noSmallPadding ? 0 : 20)}px;
  }
`

export const Quotation = styled.p`
  position: relative;
  ::before {
    content: "“";
    position: absolute;
    top: 20px;
    left: -20px;
    /* z-index: -100; */
    color: ${colors.greylight};
    font-size: 15rem;
  }
`

export const HorizontalCard = styled.section`
  width: 100%;
  background: #fff;
  position: relative;
  margin: 2.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  article {
    padding: 40px 60px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 360px;
  }

  h3 {
    font-weight: 400;
    font-size: 1.1rem;
    color: rgba(38, 37, 43, 0.75);
    margin: 0 0 0.2rem;

    strong {
      font-weight: 500;
      font-size: 1.1rem;
      color: ${props => (props.olive ? colors.olive : "#f29eba")};
    }
  }
  h4 {
    font-weight: 300;
    font-size: 0.9rem;
    color: rgba(38, 37, 43, 0.75);
    margin: 0 0 0.2rem;
  }

  h5 {
    margin: 0;
  }
  h5 a {
    text-decoration: none;
    font-weight: 400;
    font-size: 0.9rem;
    color: ${colors.olive};
    margin: 0;
  }

  p {
    font-weight: 200;
    font-size: 0.9rem;
    line-height: 1.4rem;
    color: rgba(24, 24, 28, 0.6);
    text-align: left;
    margin: 1.5rem 0 0 0;
    width: 100%;
  }

  aside {
    width: 360px;
    height: 360px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    order: ${props => (props.startLeft ? "-1" : "1")};
    flex-shrink: 0;
    background: transparent;

    .flip-image {
      width: 100%;
      height: 100%;
    }

    .number {
      font-size: 10rem;
      color: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .flipBanner {
      background: ${props => (props.olive ? colors.olive : "#f49dba")};
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8rem;
      text-transform: uppercase;
      color: #fff;
      padding: 1rem;

      svg {
        position: absolute;
        right: 1rem;
        top: 9px;
      }
    }
  }
  footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    bottom: 0px;
  }

  @media (max-width: 1350px) {
    article {
      padding: 30px 40px;
    }
    p {
      margin-top: 10px;
      font-size: 17px;
      line-height: 24px;
    }
  }
  @media (max-width: 1200px) {
    article {
      padding: 20px 40px;
    }
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: flex-start;
    width: 360px;
    text-align: center;

    p {
      text-align: center;
      margin-top: 10px;
      font-size: 14px;
    }
    &.usluga p {
      font-size: 16px;
    }
    aside {
      order: 0;
    }
    article {
      order: 1;
      padding: 12px 8px;
      height: 440px;
      button {
        margin: 0 auto;
      }
    }

    footer {
      position: absolute;
      bottom: 20px;
      left: 0;
    }
  }

  @media (max-width: 950px) {
    article {
      padding: 12px 8px;
    }
    button {
      font-size: 14px;
    }
    &.usluga p {
      margin-top: 15px;
    }
  }
  @media (max-width: 500px) {
    flex-direction: column;
    width: 315px;
    padding: 0;

    aside {
      order: 0;
      width: 315px;
      height: 315px;
    }
    &.usluga article {
      height: 387px;
    }
    article {
      order: 1;

      min-height: 0;
      height: 387px;

      h3 strong {
        font-size: 16px;
      }
      h4,
      h5 a {
        font-size: 14px;
      }
    }
    p,
    &.usluga p {
      font-size: 14px;
      line-height: 22px;
    }
    p {
      margin-top: 10px;
    }
  }
  @media (max-width: 375px) {
    width: 300px;

    aside {
      width: 300px;
      height: 300px;
    }
    article {
      h3 strong {
        font-size: 15px;
      }
      h4,
      h5 a {
        font-size: 13px;
      }
      p,
      &.usluga p {
        font-size: 12px;
        line-height: 20px;
      }
      p {
        margin-top: 8px;
      }
    }
  }
`

export const HorizontalCardContainer = styled.section`
  @media (max-width: 1100px) {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 60px 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    padding: 30px 0;
  }
  @media (max-width: 900px) {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & > ${HorizontalCard}:nth-child(2) {
      -ms-grid-column: 2;
    }
    & > ${HorizontalCard}:nth-child(3) {
      -ms-grid-column: 3;
    }
    & > ${HorizontalCard}:nth-child(4) {
      -ms-grid-column: 4;
    }
    & > ${HorizontalCard}:nth-child(5) {
      -ms-grid-column: 5;
    }
    & > ${HorizontalCard}:nth-child(6) {
      -ms-grid-column: 6;
    }
    & > ${HorizontalCard}:nth-child(7) {
      -ms-grid-column: 7;
    }
    & > ${HorizontalCard}:nth-child(8) {
      -ms-grid-column: 8;
    }
  }
`
