import React from "react"

const SvgSmallCross = props => (
  <svg width={22} height={22} fill="none" {...props}>
    <path
      d="M1 1l20 20M21 1L1 21"
      stroke="#fff"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
)

export default SvgSmallCross
