import styled from 'styled-components'
import { darken } from 'polished'
import { colors } from './theme'

export default styled.button`
  font-family: 'Fira Sans';
  width: 300px;
  height: 50px;
  background: ${props => props.bg || colors.olivetouch};
  border-radius: 7px;
  border: none;
  cursor: pointer;
  position: relative;
  font-weight: 200;
  font-size: ${props => (props.title ? '1rem' : '0.8rem')};
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.color || colors.greymid};
  transition: background-color 0.3s;

  &:hover {
    background: ${props => darken(0.05, props.bg || colors.olivetouch)};
  }

  :focus {
    outline: none;
  }

  svg {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translate(0, -50%);
  }

  @media (max-width: 650px) {
    width: 240px;
    height: 40px;
    font-size: 0.7rem;
  }

  @media (max-width: 400px) {
    font-size: 14px;
  }
`
