import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useMedia from 'use-media'
import CloseWindow from '../icons/CloseWindow'
import Button from './Button'
import PrivacyPolicy from './PrivacyPolicy'

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  min-height: 80px;
  padding: 0;
  z-index: 99;
  box-shadow: 0 -5px 30px rgba(0, 0, 0, 0.01);

  .content {
    box-sizing: border-box;
    width: 90%;
    max-width: 920px;
    margin: 0 auto;
    padding: 120px 0 50px;
    text-align: center;
  }

  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    /* or 90% */
    text-transform: uppercase;

    color: #f29eba;
    margin: 0 0 40px;
  }

  p {
    box-sizing: border-box;
    width: auto;
    margin: 0;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: rgba(38, 37, 43, 0.75);
    margin-bottom: 40px;
    > div {
      display: inline-block;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: none;
      }
      &.regular-weight {
        font-weight: 400;
      }
    }
  }
  svg {
    position: absolute;
    top: 60px;
    right: 60px;
    display: block;
    cursor: pointer;
  }
  .privacy-link {
    font-weight: 500;
    text-decoration-line: underline;
    text-transform: uppercase;
    cursor: pointer;
  }

  button {
    margin: 0 auto;
    display: block;
    font-weight: 300;
    font-size: 20px;
  }

  @media (max-width: 1021px) {
    p {
      font-size: 15px;
    }
  }
  @media (max-width: 958px) {
    h3 {
      font-size: 19px;
    }
    p {
      font-size: 14px;
    }
  }

  @media (max-width: 750px) {
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    svg {
      top: 30px;
      right: 30px;
    }
    .content {
      width: 100%;
      padding: 80px 28px 50px;
    }
    button {
      font-size: 18px;
    }
  }

  @media (max-width: 650px) {
    button {
      font-size: 14px;
    }
  }
`

const Cookies = () => {
  const [isUnderstood, setIsUnderstood] = useState(false)
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)

  const isMobile = useMedia({ maxWidth: 750 })

  useEffect(() => {
    if (localStorage.getItem('cookies')) setIsUnderstood(true)
  }, [])

  const setUnderstood = () => {
    localStorage.setItem('cookies', 'understood')
    setIsUnderstood(true)
  }

  if (isUnderstood) return null
  return (
    <Container>
      {/* if the user closes, it will still show up on every page load */}
      <CloseWindow
        stroke="#f49dba"
        onClick={setUnderstood}
        width={isMobile ? 20 : 40}
        viewBox="0 0 42 42"
        strokeWidth={isMobile ? 4 : 2}
      />
      <div className="content">
        <h3>Ova web stranica koristi kolačiće</h3>
        <p>
          <div>
            Za pružanje boljeg korisničkog iskustva i ispravan prikaz sadržaja
            ova stranica koristi kolačiće.
          </div>
          <div>
            Kolačići su anonimizirani te u svakom trenutku možete kontrolirati i
            konfigurirati postavke kolačića u vašem Internet pregledniku.
          </div>
          <div className="regular-weight">
            Više o kolačićima možete pročitati u našoj{' '}
            <span
              className="privacy-link"
              onClick={() => setShowPrivacyPolicy(true)}
            >
              Politici privatnosti
            </span>
          </div>
          .
        </p>
        <Button color="#fff" bg="#F49dba" onClick={setUnderstood}>
          Razumijem
        </Button>
      </div>
      {showPrivacyPolicy ? (
        <PrivacyPolicy onClose={() => setShowPrivacyPolicy(false)} />
      ) : (
        ''
      )}
    </Container>
  )
}

export default Cookies
