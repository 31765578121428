export const colors = {
  pinklightest: '#FEFBFC',
  pinktouch: '#FEF4F7',
  pinkhot: '#EA3579',
  pinkdirty: '#F49DBA',
  pinkpastel: '#F9CAD5',

  olivelightest: '#FDFDFC',
  olivetouch: '#F5F5F2',
  olive: '#9C997B',
  olivepastel: '#C6C4B2',

  greylight: '#e5e5e5',
  greymid: '#5c5b5f',
  greydark: '#201E25',
}
