import React from 'react'

const SvgCloseWindow = ({
  stroke = '#fff',
  strokeWidth = 2,
  width = 42,
  ...props
}) => (
  <svg width={width} fill="none" viewBox="0 0 42 42" {...props}>
    <path
      d="M1 1l40 40M41 1L1 41"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeMiterlimit={10}
    />
  </svg>
)

export default SvgCloseWindow
