// https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
// but here we will tweak it and just use the x position
import { useRef, useEffect } from 'react'
import throttle from 'lodash.throttle'

const isBrowser = typeof window !== 'undefined'

const getScrollPosition = ({ element }) => {
  if (!isBrowser) return 0
  const target = element ? element.current : document.body
  if (!target) return 0
  return target.getBoundingClientRect().top
}

const useScroll = (effect, deps, element) => {
  const position = useRef(getScrollPosition({ element }))

  useEffect(() => {
    const callback = () => {
      const current = getScrollPosition({ element })
      effect({ previous: position.current, current })
      position.current = current
    }
    const throttledCallback = throttle(callback, 100)

    window.addEventListener('scroll', throttledCallback, {
      capture: false,
      passive: true,
    })
    return () => window.removeEventListener('scroll', callback)
    // eslint-disable-next-line
  }, [])
}

export default useScroll
