import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useMedia from 'use-media'
import { useTransition, animated } from 'react-spring'
import Portal from './Portal'
import CloseWindow from '../icons/CloseWindow'
import { colors } from './theme'

const Container = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100vw;
  height: 100vh;
  background: #fff;

  .scrollable {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
  }
`

const StyledClose = styled(CloseWindow)`
  position: absolute;
  top: 60px;
  right: 60px;
  cursor: pointer;
  z-index: 99999999999999999;

  @media (max-width: 650px) {
    top: 30px;
    right: 30px;
  }
`

const Content = styled.div`
  padding: 120px 0 160px;
  margin: 0 auto;
  width: 90%;
  max-width: 850px;

  h2 {
    font-weight: 500;
    font-size: 1.05rem;
    line-height: 0.9rem;
    text-transform: uppercase;
    color: #e8397a;
    margin-bottom: 40px;
    width: auto;
    text-align: left;
  }
  h3 {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.9rem;
    color: #e8397a;
    margin: 40px 0 20px;
  }
  p {
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 0.9rem;
    color: ${colors.greymid};
    margin: 0 0 20px;

    em,
    a {
      font-style: normal;
      font-weight: 500;
      color: #e8397a;
    }
  }
  @media (max-width: 650px) {
    padding: 80px 28px 50px;
    width: 100%;
  }
`

const PrivacyPolicy = ({ onClose }) => {
  const [toggle, setToggle] = useState(false)
  useEffect(() => {
    setToggle(true)
  }, [])

  const background = useTransition(toggle, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const handleClose = () => {
    setToggle(prev => !prev)
    setTimeout(onClose, 600)
  }

  const isMedium = useMedia({ maxWidth: 1100 })
  return (
    <Portal>
      {background.map(
        ({ item, key, props }) =>
          item && (
            <Container key={key} style={props}>
              <StyledClose
                onClick={handleClose}
                stroke="#EA3579"
                strokeWidth={isMedium ? 4 : 2}
                width={isMedium ? 20 : 40}
              />
              <div className="scrollable" body-lock-scroll-ignore>
                <Content>
                  <h2>Pravila privatnosti</h2>
                  <p>
                    <em>Doula Sanja</em> iznimno cijeni poštenje i jasnoću te
                    smo predani stvaranju snažnog i dugotrajnog odnosa s našim
                    klijentima na temelju povjerenja i međusobne dobrobiti.
                    Ovakva predanost dijelom podrazumijeva zaštitu i poštivanje
                    vaše privatnosti i izbora. Poštivanje vaše privatnosti naš
                    je prioritet. Zbog toga u nastavku navodimo tekst Pravila
                    privatnosti u skladu s Uredbom EU) 2016/679 Europskog
                    parlamenta i Vijeća od 27. travnja 2016. o zaštiti
                    pojedinaca u vezi s obradom osobnih podataka i o slobodnom
                    kretanju takvih podataka te o stavljanju izvan snage
                    Direktive 95/46/EZ (u daljnjem tekstu: „Uredba“) i drugim
                    primjenjivim propisima.
                  </p>
                  <p>
                    Kad prikupljamo vaše osobne podatke ili kad ih dijelite s
                    nama, upotrebljavamo ih u skladu s ovim Pravilima. Pažljivo
                    pročitajte ove informacije. Ako imate pitanja ili nedoumice
                    u vezi s osobnim podacima, obratite nam se na{' '}
                    <a href="mailto:sanjadoula1@gmail.com">
                      sanjadoula1@gmail.com
                    </a>
                    .
                  </p>
                  <p>
                    <em>DOULA SANJA</em> je odgovorno za osobne podatke koje
                    dijelite s nama, u svojstvu „voditelja obrade” podataka za
                    potrebe važećih zakona o zaštiti podataka.
                  </p>
                  <p>
                    <em>
                      DOULA SANJA, vl. Sanja Bukač Štritof
                      <br />
                      Ulica kneza Domagoja 4, Zagreb
                    </em>
                  </p>
                  <h3>I. PRIKUPLJANJE I OBRADA</h3>
                  <p>
                    Osobni podatak je svaki podatak kojim se utvrđuje ili može
                    utvrditi Vaš identitet, npr. ime I prezime, adresa
                    prebivališta, e-mail adresa, itd.
                  </p>
                  <p>
                    Podatke prikupljamo i primamo putem naših web-mjesta,
                    formulara, aplikacija, ili na druge načine. Ponekad nam ih
                    dajete izravno (npr. kad stvarate korisnički račun, kad nam
                    se obraćate), ponekad ih mi prikupljamo (npr. upotrebljavamo
                    kolačiće).
                  </p>
                  <p>
                    Ovisno o svrsi upotrebe podataka, pravna osnova za obradu
                    vaših podataka može biti:
                    <br />
                    • Vaša suglasnost;
                    <br />
                    • Naš legitimni interes koji može biti:
                    <br />◦ Poboljšanje naših usluga: točnije, naši poslovni
                    interesi koji nam pomažu bolje razumjeti vaše potrebe i
                    očekivanja, a samim time i poboljšati usluge, web-mjesta, u
                    Vašu korist;
                    <br />◦ Osiguranje alata: održavanje alata kojima se
                    koristite (web-stranice) sigurnima za upotrebu i ispravnima
                    te osiguravanje njihovog neprekidnog poboljšanja.
                    <br />• Izvršenje ugovora: točnije, izvršenje usluga koje
                    ste zatražili;
                    <br />• Pravne osnove koje zakonom zahtijevaju obradu.
                  </p>
                  <p>
                    Kako bi Vam bili u mogućnosti pružiti traženu uslugu,
                    potrebno je obrađivati minimalan set podataka neophodan za
                    kvalitetno pružanje pojedine usluge. U suprotnom, ako ne
                    ustupite ustupiti traženi set podataka nećemo Vam biti u
                    mogućnosti pružiti zahtijevanu uslugu.
                  </p>
                  <p>
                    Vaše podatke prikupljamo kada kreirate i upravljate svojim
                    korisničkim računom na web-mjestima te ti podaci mogu
                    uključivati ime i prezime, spol, adresu e-pošte, kućnu
                    adresu, telefonski/mobilni broj; fotografiju, datum rođenja
                    ili dobnu skupinu, ID, korisničko ime i zaporku.
                    Upotrebljavamo ih radi odgovaranja na vaša pitanja i druge
                    vrste interakcije s time da je pravna osnova prikupljanja
                    izvršenje ugovora, specifično za pružanje usluge koju ste
                    zatražili (npr. stvaranje korisničkog računa).
                  </p>
                  <p>
                    Vaše podatke prikupljamo temeljem Vaše suglasnosti kada
                    postavljate pitanja liječniku, a u cilju pružanja odgovora
                    na Vaša pitanja, kao i kada se prijavljujete na radionicu u
                    cilju dostave uputa i termina radionice te sudjelovanja na
                    istoj, s time da ti podaci mogu uključivati, ovisno o vašoj
                    interakciji, ime i prezime, telefonski/mobilni broj, adresu
                    e-pošte, ostale informacije o sebi koje ste podijelili s
                    nama prilikom upita / prijave (mogu uključivati podatke o
                    dobrobiti i zdravlju).
                  </p>
                  <p>
                    Također, ako date svoj pristanak, Vaše podatke koje ste
                    pružili pri prijavi na radionicu, možemo koristiti kako
                    bismo Vas obavijestili o našim daljnim ponudama i uslugama,
                    te fotografije sa radionica u srhu promocije. Ako uskratite
                    svoj pristanak i dalje ćete biti u mogućnosti sudjelovati u
                    radionici.
                  </p>
                  <p>Imate pravo povući svoju suglasnost u svako doba.</p>
                  <p>
                    Prilikom pretraživanja naše web stranice prikupljamo Vaše
                    podatke pomoću kolačića ili sličnih tehnologija kako bismo
                    osigurali ispravan rad web stranice (legitimni interes).
                    Ovisno o učestalosti naše interakcije podaci mogu
                    uključivati podatke o vašoj upotrebi naših web-mjesta,
                    podrijetlo, detalje prijave, stranice koje ste posjetili,
                    videozapise koje ste pogledali, oglase na koje kliknete,
                    lokaciju, trajanje posjeta, tehničke informacije kao npr. IP
                    adresu, informacije preglednika, informacije o uređaju, te
                    jedinstveni identifikator dodijeljen svakom posjetitelju i
                    datum prestanka važenja identifikatora.
                  </p>
                  <p>
                    Vaše osobne podatke pohranjujemo samo dok su nam potrebni u
                    svrhe u koje ih pohranjujemo, da bismo ispunili Vaše potrebe
                    ili zakonske obveze. Kad nam vaši osobni podaci Više nisu
                    potrebni, uklanjamo ih iz sustava i arhiva ili ih pretvaramo
                    u anonimne podatke tako da vas više ne možemo
                    identificirati.
                  </p>
                  <h3>II. TKO MOŽE PRISTUPITI VAŠIM OSOBNIM PODACIMA?</h3>
                  <p>
                    Ako postavljate pitanja za naše liječnike, Vaše podatke
                    zajedno s upitom dostavljamo liječniku u cilju pružanja
                    odgovora na Vaša pitanja.
                  </p>
                  <p>
                    Ako se prijavite na radionicu, Vaše podatke prosljeđujemo
                    suradnicama tima u cilju organizacije radionice.
                  </p>
                  <h3>III. VAŠA PRAVA</h3>
                  <p>
                    Ispitanik ostvaruje sljedeća prava u skladu s uvjetima koje
                    propisuje Uredba:
                    <br />
                    a) pravo na povlačenje pristanka u bilo kojem trenutku, a da
                    to ne utječe na zakonitost obrade koja se temeljila na
                    pristanku prije nego što je povučen;
                    <br />
                    b) pravo na pristup osobnim podacima;
                    <br />
                    c) pravo na ispravak osobnih podataka;
                    <br />
                    d) pravo na brisanje;
                    <br />
                    e) pravo na ograničavanje obrade;
                    <br />
                    f) pravo na ulaganje prigovora na obradu Društvu i / ili
                    Agenciji za zaštitu osobnih podataka;
                    <br />
                    g) pravo na prenosivost podataka.
                    <br />
                  </p>
                  <h3>IV. PRAVO NA INFORMACIJE</h3>
                  <p>
                    Sve informacije vezane uz Vaša prava, prikupljanje i obradu
                    Vaših osobnih podataka možete zatražiti od našeg djelatnika
                    zaduženog za zaštitu osobnih podataka putem e-mail adrese:{' '}
                    <a href="mailto:sanjadoula1@gmail.com">
                      sanjadoula1@gmail.com
                    </a>
                    .
                  </p>
                  <h3>
                    V. ANONIMNO PRIKUPLJANJE PODATAKA NA NAŠOJ WEB-STRANICI
                  </h3>
                  <p>
                    Vaš preglednik prenosi informacije koje se automatski
                    prikupljaju i pohranjuju u datoteke sa zapisima na
                    poslužitelju Društva. Radi se o, primjerice, informacijama
                    o:
                    <br />
                    - vrsti/inačici preglednika korištenom operativnom sustavu
                    <br />
                    - identifikacijskoj URL adresi (prethodno posjećena
                    web-stranica)
                    <br />
                    - nazivu hosta pristupnog računala (IP adresa)
                    <br />
                    - vremenu zahtjeva računala
                    <br />
                  </p>
                  <p>
                    Te podatke Društvo ne može povezati s osobama. Ti se podaci
                    ne pripajaju ostalim izvorima podataka i brišu se nakon
                    statističke analize.
                  </p>
                  <h3>VI. SIGURNOST</h3>
                  <p>
                    Kontinuirano poduzimamo sve tehničke i organizacijske mjere
                    sigurnosti radi zaštite Vaših osobnih podataka. Vaši podaci
                    zaštićeni su od gubitka, uništenja,
                    mijenjanja/krivotvorenja, manipulacije i nedopuštenog
                    pristupa.
                  </p>
                  <p>
                    Ne dopuštamo neovlašteno prikupljanje, obradu ili korištenje
                    osobnih podataka. Primjenjuje se pravilo ograničavanja
                    pristupa podacima samo na one podatke koji su potrebni za
                    obavljanje pojedinih poslovnih zadataka odnosno koji su
                    potrebni za ostvarenje svrhe u koju su ti osobni podaci i
                    pruženi.
                  </p>
                  <p>
                    Mehanizmi zaštite se primjenjuju na sve Vaše osobne podatke
                    bez obzira u kojem se obliku oni čuvaju – papirnatom ili
                    elektroničkom.
                  </p>
                  <h3>VII. OBAVIJEST O PROMJENAMA</h3>
                  <p>
                    Sve promjene ovih Pravila privatnosti bit će objavljene na
                    našim Internet stranicama i na drugim mjestima na kojima
                    smatramo da je to potrebno.
                  </p>
                </Content>
              </div>
            </Container>
          )
      )}
    </Portal>
  )
}

export default PrivacyPolicy
