import React, { useMemo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useTransition, useTrail, animated } from 'react-spring'

import Portal from '../Portal'
import SmallClose from '../../icons/SmallClose'
import { colors } from '../theme'

const Container = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100vw;
  overflow: auto;
  background: ${colors.pinkdirty};
  height: 100vh;
`
const StyledSmallClose = styled(SmallClose)`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`
const Content = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ul {
    padding: 0;
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const AnimatedLink = styled(animated(Link))`
  text-decoration: none;
  padding: 1.5rem 2rem;
  li {
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    color: #fff;
  }
`

const FullScreenNav = ({ onClose }) => {
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    setToggle(true)
  }, [])

  const links = useMemo(
    () => [
      {
        href: '/',
        name: 'Naslovnica',
      },
      {
        href: '/o-nama',
        name: 'O Nama',
      },
      {
        href: '/usluge',
        name: 'Usluge',
      },
      {
        href: '/cesta-pitanja',
        name: 'Česta pitanja',
      },
      {
        href: '/blog',
        name: 'Blog',
      },
      {
        href: '/kontakt',
        name: 'Kontakt',
      },
    ],
    []
  )

  const config = { mass: 1, tension: 700, friction: 80 }

  const trail = useTrail(links.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 40,
    from: { opacity: 0, x: 40 },
  })
  const background = useTransition(toggle, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const handleClose = () => {
    setToggle(prev => !prev)
    setTimeout(onClose, 600)
  }

  return (
    <Portal>
      {background.map(
        ({ item, key, props }) =>
          item && (
            <Container key={key} style={props}>
              <Content>
                <ul>
                  {trail.map(({ x, height, ...rest }, index) => (
                    <AnimatedLink
                      key={links[index].href}
                      to={links[index].href}
                      onClick={handleClose}
                      style={{
                        // ...rest,
                        transform: x.interpolate(
                          x => `translate3d(${x}px,0,0)`
                        ),
                      }}
                    >
                      <li>{links[index].name}</li>
                    </AnimatedLink>
                  ))}
                </ul>
              </Content>
              <StyledSmallClose onClick={handleClose} />
            </Container>
          )
      )}
    </Portal>
  )
}

export default FullScreenNav
