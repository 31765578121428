import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'

// make sure that the document object is defined
const isDocument = typeof document !== 'undefined'
const portalRoot = isDocument ? document.getElementById('portal') : null
const el = isDocument ? document.createElement('div') : null

const Portal = ({ children }) => {
  useEffect(() => {
    portalRoot.appendChild(el)
    // return () => portalRoot.removeChild(el)
  }, [])

  // Check that this.el is not null before using ReactDOM.createPortal
  if (!el) return null
  return ReactDOM.createPortal(<RemoveScroll>{children}</RemoveScroll>, el)
}

export default Portal
