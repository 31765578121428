import React from 'react'
import PropTypes from 'prop-types'

const SvgDownTriangle = ({ fill = '#5c5b5f' }) => (
  <svg width={12} height={9} fill="none">
    <path d="M6 9L.804 0h10.392L6 9z" fill={fill} />
  </svg>
)
SvgDownTriangle.propTypes = {
  fill: PropTypes.string,
}

export default SvgDownTriangle
